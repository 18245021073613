import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ConsentComponent } from '@src/app/components/consent/consent.component'
import { SharedModule } from '@src/app/shared/shared.module'
import { ButtonComponentModule } from '../button/button.component.module'

@NgModule({
  declarations: [ConsentComponent],
  imports: [CommonModule, SharedModule, ButtonComponentModule],
  exports: [ConsentComponent],
  providers: [],
})
export class ConsentComponentModule {}
