import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { GoogleAuthComponent } from '@src/app/components/auth/providers/google/google-auth.component'
import { SharedModule } from '@src/app/shared/shared.module'
import { ButtonComponentModule } from '../../../button/button.component.module'

@NgModule({
  declarations: [GoogleAuthComponent],
  imports: [CommonModule, SharedModule, ButtonComponentModule],
  exports: [GoogleAuthComponent],
})
export class GoogleAuthComponentModule {}
