import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SharedModule } from '@src/app/shared/shared.module'
import { ButtonComponentModule } from '../../../button/button.component.module'
import { AppleAuthComponent } from './apple-auth.component'

@NgModule({
  declarations: [AppleAuthComponent],
  imports: [CommonModule, SharedModule, ButtonComponentModule],
  exports: [AppleAuthComponent],
})
export class AppleAuthComponentModule {}
