import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AuthComponent } from '@src/app/components/auth/auth.component'
import { AppleAuthComponentModule } from '@src/app/components/auth/providers/apple/apple-auth.component.module'
import { GoogleAuthComponentModule } from '@src/app/components/auth/providers/google/google-auth.component.module'
import { ButtonComponentModule } from '@src/app/components/button/button.component.module'
import { ConsentComponentModule } from '@src/app/components/consent/consent.component.module'
import { TextInputComponentModule } from '@src/app/components/input/text/text-input.component.module'
import { SharedModule } from '@src/app/shared/shared.module'
import { EmailAuthComponent } from './providers/email/email-auth.component'

@NgModule({
  declarations: [AuthComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    ConsentComponentModule,
    TextInputComponentModule,
    GoogleAuthComponentModule,
    AppleAuthComponentModule,
    EmailAuthComponent,
    ButtonComponentModule,
  ],
  exports: [AuthComponent],
})
export class AuthComponentModule {}
